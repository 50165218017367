<template>
  <v-dialog v-model="dialog" persistent max-width="600px" eager origin="center">
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title> {{ title }} </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <h3 class="mt-2 mb-2">Detalhes</h3>
              <v-row>
                <v-col>
                  <company-select ref="CompanySelect" v-model="form.company" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <app-text-field label="Nome" v-model="form.name" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="form.type" row>
                    <v-radio label="Caixa diário" value="drawer"></v-radio>
                    <v-radio label="Conta caixa" value="local"></v-radio>
                    <v-radio label="Conta bancária" value="bank"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <template v-if="form.type == 'bank'">
                <v-row>
                  <v-col>
                    <app-text-field label="Banco" v-model="form.bank" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <app-text-field label="Conta Bancária" v-model="form.bank_account" />
                  </v-col>
                  <v-col>
                    <app-text-field label="Agência" v-model="form.bank_agency" />
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-col>
                  <employee-select v-model="form.employees" multiple chips />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="form.status" value="value" :label="statusText[form.status]" color="primary"
                    hide-details :true-value="1" :false-value="0" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end py-4" style="background-color: #f5f5f5">
          <v-btn @click="dialog = false" text class="text-capitalize" color="secondary">Cancelar</v-btn>
          <v-btn @click="handleSave()" class="text-capitalize" color="primary">Salvar</v-btn>
          <!-- <v-btn @click="dialog = false" class="text-capitalize" color="primary"
            >Salvar e continuar</v-btn
          > -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect";
import EmployeeSelect from "@/components/employees/ui/EmployeeSelect.vue";

export default {
  components: { CompanySelect, EmployeeSelect },

  data: () => ({
    dialog: false,
    valid: true,

    statusText: {
      1: "Este cadastro está ativo",
      0: "Este cadastro está inativo",
    },

    title: "Novo Caixa",

    form: {},

    baseForm: {
      name: null,
      type: "local",
      bank: null,
      bank_account: null,
      bank_agency: null,
      company: null,
      status: null,
      id: null,
      status: 1,
      employees: [],
    },
  }),

  computed: {
    isInsert() {
      return !this.form.id;
    },
  },

  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();

      if (id) {
        await this.showAccount(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.title = "Cadastrar Caixa";
    },

    async showAccount(id) {
      this.title = "Atualizar Caixa";

      this.$store.commit("app/showLoading");

      await this.$http.$get("account/account/" + id).then((response) => {
        this.form = response.account;
        this.form.employees = response.account.employees;
        this.$store.commit("app/hideLoading");
      });
    },

    async handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        this.$store.commit("app/showLoading");
        if (this.isInsert) {
          await this.store();
        } else {
          await this.update();
        }
        this.$store.commit("app/hideLoading");
      }
    },

    async store() {
      await this.$http.store("account/account", this.form).then((response) => {
        this.processSaved();
      });
    },

    async update() {
      await this.$http
        .update("account/account", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

<style></style>